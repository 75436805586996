* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  position: relative;
}

html {
  min-height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  min-height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  /*min-height: fill-available;*/
}

p,
ul li {
  color: #616161;
  text-align: justify;
}

hr:not([size]) {
  height: 0.1px;
}

.display-grid {
  display: flex;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.dropdown .btn:focus {
  box-shadow: none;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: initial;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

/*-------------------------scroll baar -------------------------*/
body::-webkit-scrollbar {
  width: 8px;
  background-color: #19875400;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(245, 239, 239, 0.3);
  border-radius: 0;
  background-color: #4c4c4c;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

/*-------------------------scroll baar -------------------------*/

.img-responsive,
.thumbnail a>img,
.thumbnail>img {
  display: block;
  max-width: 100%;
  height: auto;
}

.no-data-found {
  text-align: center;
  color: #ccc;
  font-size: 25px;
  padding: 0 20px;
  margin-top: 95px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data-found small {
  color: #ccc;
  font-size: 15px
}

.no-data-found p {
  color: #ccc;
}

.no-data-found img {
  width: 50px;
  margin-bottom: 5px;
}

.loader-position {
  position: relative;
  top: 50%;
  padding: 30px 0;
}

.loader-position .msg {
  position: absolute;
  color: #333;
  left: 0;
  margin-left: -72px;
  top: 110px;
  margin-top: 0;
  font-size: 12px;
  letter-spacing: 2px
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #f0df20;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.youtube-modal .mat-dialog-container .btn-close {
  position: absolute;
  top: -22px;
  right: -18px;
  z-index: 999;
  background-color: #fff;
  opacity: 1;
  font-size: 10px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #f0df20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.youtube-modal .mat-dialog-container .btn-close:hover {
  background-color: #f00;
  border-color: #fff;
  color: #fff;
}

.youtube-modal {
  background-color: transparent !important;
  position: relative !important;
}

.youtube-modal .modal-dialog {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 78px 30px 0;
  width: auto;
  max-width: 1000px;
}

.youtube-modal .mat-dialog-container {
  padding: 3px 3px;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.youtube-modal .mat-dialog-container .mat-dialog-content {
  margin: auto;
  padding: 0;
}

.youtube-modal .modal-body img {
  width: 100%;
}

.youtube-modal .modal-dialog .btn-close {
  position: absolute;
  top: -25px;
  right: -25px;
  z-index: 999;
  background-color: #fff;
  opacity: 1;
  font-size: 10px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #f0df20;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f0df20;
} */

 .hide {
  display: none !important;
}

.btn_cht {
  font-size: 14px;
  padding: 5px 25px;
  display: block;
  border-radius: 10px;
  text-decoration: none;
  text-transform: capitalize;
  transition: all ease-in-out 0.3s;
  outline: none;
  float: right;
}

.form-group {
  margin-bottom: 15px !important;
  position: relative;
}

.form-group.message {
  text-align: center;
  color: green;
}

/* loader for page css */

.loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid #f0df20;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.loader.first-page {
  position: fixed;
  top: 50%;
  left: calc(50% - 25px);
}

.loader-ticket {
  border: 5px solid transparent;
  border-radius: 50% !important;
  border-top: 5px solid #f0df20;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.lb-data .lb-close {
  display: none !important;
}

.no-tickets {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-ticket.first-page {
  position: absolute;
  top: 50%;
  left: 50%;
}

.image_astrologer_chat img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mat-img {
  width: 20px;
  margin-right: 5px;
  border: 1px solid #999;
}

.message_image img {
  width: auto !important;
  cursor: pointer;
  border-radius: 5px;
}

.mat-select-panel::-webkit-scrollbar {
  width: 5px;
}

.mat-select-panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mat-select-panel::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey !important;
}

.mat-horizontal-content-container {
  padding: 0 0 24px 0 !important;
}

.timepicker-button {
  color: #000 !important;
}

.timepicker__header {
  background-color: #ffea00 !important;
}

.timepicker-dial__item {
  color: #606060 !important;
}

.clock-face__number>span.active {
  background-color: #ffea00 !important;
  color: #000 !important;
}

.clock-face__clock-hand {
  background-color: #ffea00 !important;
}

.clock-face__clock-hand:after {
  background-color: #ffea00 !important;
}

.clock-face__clock-hand_minute:before {
  border-color: #ffea00 !important;
}

.mat-horizontal-stepper-header {
  padding: 0 10px !important;
  height: 35px !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 10px !important;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: unset !important;
}

.mat-stepper-horizontal-line {
  margin: 0 0 !important;
  min-width: 0 !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ffea00 !important;
  color: #000000;
}

#yoginiMobile .mat-step-header .mat-step-label,
#pills-tab-yogini .mat-step-header .mat-step-label {
  min-width: auto;
}

.why_astrology {
  background-color: #eee;
  padding: 40px 0 30px;
  text-align: center;
}

.why_astrology .heading_seo_content_headline_chinese_horoscope {
  text-align: center;
  color: #313131;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 24px;
}

.why_astrology p {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: justify;
}

.why_astrology p strong {
  color: #454545;
}

.why_astrology_hide_and_show {
  margin-top: 0;
}

.heading_faqs_about_astrology {
  text-align: center;
  color: #313131;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.heading_faqs_about_astrology .fa-chevron-down {
  height: 28px;
  width: 28px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #f0df20;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: absolute;
  right: 315px;
}

.faqs_about_astrology {
  background-color: #f6f6f6;
  padding: 40px 0 30px;
  text-align: center;
}

.subheading_faqs_about_astrology {
  text-align: center;
  color: #616161;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 18px;
}

.subheading_why_astrology {
  text-align: center;
  color: #616161;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 18px;
}

.faqs_about_astrology p {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: justify;
}

.faqs_about_astrology p strong {
  color: #454545;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #f0df20;
  border: 1px solid #f0df20;
}

.form-control:disabled,
.form-control:read-only {
  background-color: #ffffff;
}

.form-control:focus {
  background-color: #fff;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.fix-h-150 {
  height: 150px;
}

.fix-ht-wt-100 {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.mat-select-search-input:focus-visible {
  outline: none;
}

.mat-select-search-input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.w-nav-overlay {
  position: fixed;
}

/* CSS Globel Filter Yaha Tak Hua hai */

.bread {
  background-color: #eeeeee;
}

ul.breadcrumbs-two {
  margin: 0;
  padding: 6px 0;
  list-style: none;
}

.breadcrumbs-two {
  overflow: hidden;
  width: 100%;
}

.breadcrumbs-two li {
  float: left;
  margin: 0 0.5em 0 0.6em;
}

.breadcrumbs-two li:nth-child(1) {
  margin: 0 0.5em 0 0.1em;
}

.breadcrumbs-two li:nth-child(1) a {
  min-width: 28px;
}

.breadcrumbs-two li a,
.breadcrumbs-two li div {
  background: #cccccc;
  padding: .13em .4em;
  float: left;
  text-decoration: none;
  color: #454545;
  position: relative;
  height: 28px;
}

.breadcrumbs-two li a::before,
.breadcrumbs-two li div::before {
  content: "";
  position: absolute;
  top: 50%;
  border-width: 13px 0 13px 15px;
  border-style: solid;
  border-color: #cccccc #cccccc #cccccc transparent;
  left: -14px;
  transform: translateY(-50%);
  height: 100%;
}

.breadcrumbs-two li div.active,
.breadcrumbs-two li a.active {
  background: #f0df20;
  color: #333333;
}

.breadcrumbs-two li div.active:hover,
.breadcrumbs-two li a.active:hover {
  cursor: default;
  box-shadow: none;
  transition: none;
  background: #f0df20;
  color: #333333;
}

.breadcrumbs-two li a:hover {
  box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.3);
  background: #cccccc;
  color: #333333;
  transition: box-shadow 200ms;
  cursor: pointer;
}

.breadcrumbs-two li:nth-child(1) a::before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: 0;
  border-width: 0;
  border-style: solid;
  border-color: #F1f2f2 #F1f2f2 #F1f2f2 transparent;
  left: 0;
}

.breadcrumbs-two li a.active::before,
.breadcrumbs-two li div.active::before {
  content: "";
  position: absolute;
  top: 50%;
  border-width: 13px 0 13px 15px;
  border-style: solid;
  border-color: #cccccc #cccccc #cccccc transparent;
  left: -14px;
  transform: translateY(-50%);
  height: 100%;
}

.breadcrumbs-two li a:hover::before,
.breadcrumbs-two li div:hover::before {
  border-color: #cccccc #cccccc #cccccc transparent;
}

.breadcrumbs-two li div.active::before,
.breadcrumbs-two li a.active::before {
  border-color: #f0df20 #f0df20 #f0df20 transparent;
}

.breadcrumbs-two li:nth-last-child(1) a::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  right: 0;
  border-radius: 5px;
}

.breadcrumbs-two li a::after,
.breadcrumbs-two li div::after {
  content: "";
  position: absolute;
  top: 50%;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid #cccccc;
  right: -12px;
  z-index: 99;
  transform: translateY(-50%);
  height: 100%;
}

.breadcrumbs-two li a:hover::after,
.breadcrumbs-two li div:hover::after {
  border-left-color: #cccccc;
  color: #333333;
}

.breadcrumbs-two .current,
.breadcrumbs-two .current:hover {
  background: none;
}

.breadcrumbs-two .current::after,
.breadcrumbs-two .current::before {
  content: normal;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 999999 !important;
}

.slick-arrow.slick-disabled.verti {
  background-color: #f3f2f2 !important;
  cursor: not-allowed;
}

.width_modal_dialog .modal-body::-webkit-scrollbar {
  width: 7px;
}

.width_modal_dialog .modal-body::-webkit-scrollbar-track {
  background-color: #eee;
}

.width_modal_dialog .modal-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.form-control {
  font-size: 14px;
}

/* one image usage css for icons */
.icons_separate_image {
  background-image: url(https://aws.astrotalk.com/assets/images/icons.webp);
  display: inline-block;
  z-index: 99;
}

.chat_with_astrologer_icon {
  height: 65px;
  width: 65px;
  background-position: 439px 713px;
}

.talk_with_astrologer_icon {
  height: 65px;
  width: 65px;
  background-position: 528px 716px;
}

.live_on_astrotv_icon {
  height: 65px;
  width: 65px;
  background-position: 343px 713px;
}

.astromall-position-icon {
  height: 65px;
  width: 65px;
  background-position: 254px 713px;
}

.blog_position_icon {
  height: 65px;
  width: 65px;
  background-position: 85px 448px;
}

.free_services_crous_next {
  height: 35px;
  width: 35px;
  background-position: 155px -31px;
  position: absolute;
  right: -35px;
  top: 45%;
  cursor: pointer;
}

.free_services_crous_prev {
  height: 35px;
  width: 35px;
  background-position: 106px -31px;
  position: absolute;
  left: -35px;
  top: 45%;
  cursor: pointer;
}

.modal-body {
  padding: 0;
}

.display_flex .modal-dialog {
  min-width: 500px;
}

.modal-content {
  border-radius: 10px;
}

.display_flex {
  display: flex !important;
  align-items: center !important;
  z-index: 9999;
}

.width_modal_dialog .modal-dialog {
  min-width: 600px;
}

.display_flex .modal-content {
  border: none;
}

.width_modal_dialog .modal-body {
  padding: 12px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #000;
}

button.cancelBtn {
  background-color: #eee;
}

button.deleteBtn {
  background-color: #f0df20;
}

button.deleteBtn.danger {
  background-color: red;
  color: #ffffff;
}

::ng-deep span.read svg path {
  color: #4fc3f7;
}

.toast-message {
  color: #ffffff;
}

/* Media Query For Devices */

@media screen and (min-width: 1400px) {
  .container {
    padding: 0 100px;
  }
}

@media screen and (min-width: 1600px) {
  .heading_faqs_about_astrology .fa-chevron-down {
    right: 400px !important;
  }

  .container {
    max-width: 1400px;
  }
}

@media screen and (min-width: 1800px) {
  .container {
    padding: 0;
  }
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
  .customer_support_chat_section .col-xl-4 {
    padding-right: calc(var(--bs-gutter-x) / 2) !important;
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
  }

  .customer_support_chat_section .col-xl-8 {
    padding-right: calc(var(--bs-gutter-x) / 2) !important;
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
  }

  #message_form {
    padding: 10px 0 10px 25px !important;
  }

  .scroll_down_to_message {
    right: 9px !important;
  }

  .no-data-found {
    margin-top: 87px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .customer_support_chat_section {
    padding-top: 93px !important;
  }

  .free_services_crous_next {
    right: -25px;
  }

  .free_services_crous_prev {
    left: -25px;
  }
}

@media screen and (max-width: 992px) {
  .card {
    margin: 1rem 0.7rem 1rem;
  }

  .section-headings h4::after,
  .section-headings h4::before {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .customer_support_chat_section {
    padding-top: 63px !important;
  }
}

@media screen and (max-width: 768px) {

  .ng-image-slider,
  .ng-image-slider .main {
    height: 100px !important;
  }

  .display_flex_use {
    padding: 0 10px;
  }

  .free_services_crous_next {
    right: -10px;
  }

  .free_services_crous_prev {
    left: -10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .customer_support_chat_section {
    padding-top: 63px !important;
  }

  .container {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  ul.breadcrumbs-two {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .display_flex .modal-dialog {
    min-width: 100%;
    max-width: 100%;
    margin: 0 auto;
    width: 100% !important;
    justify-content: flex-end !important;
  }

  .no-data-found {
    font-size: 22px !important;
  }

  .ribbon span {
    font-size: 8px;
    line-height: 18px;
    width: 80px;
    top: 16px;
    right: 0;
    box-shadow: 0 0 4px #ccc !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .width_modal_dialog .modal-dialog {
    min-width: auto !important;
    padding: 0 10px !important;
  }

  .modal-header .btn-close {
    padding: .5rem 0;
    margin: 0;
  }

  .ribbon {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 53px;
    text-align: left;
    left: -16px;
    top: -10px;
    transform: rotate(269deg);
  }

  i.fa.fa-search {
    right: 27px !important;
  }

  .mat-stepper-horizontal-line {
    margin: 1px 1px !important;
    max-width: 1px !important;
    border-top-width: 30px !important;
  }

  .mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 2px !important;
  }

  .mat-step-icon {
    height: 16px !important;
    width: 16px !important;
  }

  .mat-step-header .mat-step-icon {
    font-size: 12px !important;
  }

  .ast_team_box img {
    width: 110px;
    height: 110px;
  }

  .why_astrology {
    background-color: #eee;
    padding: 20px 0 15px;
  }

  .faqs_about_astrology {
    padding: 20px 0 15px;
  }

  .heading_faqs_about_astrology .fa-chevron-down {
    right: 0 !important;
  }

  .subheading_why_astrology {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .why_astrology h5 {
    font-size: 17px !important;
  }

  .why_astrology p {
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .faqs_about_astrology p {
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .subheading_faqs_about_astrology {
    font-size: 17px !important;
    margin-bottom: 10px;
  }

  .heading_faqs_about_astrology {
    margin-bottom: 5px !important;
    font-size: 18px !important;
    align-items: center !important;
  }

  .faqs_about_astrology h5 {
    font-size: 17px !important;
    margin-bottom: 10px;
  }

  .why_astrology .heading_seo_content_headline_chinese_horoscope {
    margin-bottom: 5px !important;
    font-size: 18px !important;
    align-items: flex-start !important;
    padding: 0;
  }

  .width_modal_dialog .modal-body::-webkit-scrollbar {
    display: none;
  }

  .ng-image-slider {
    margin-top: 10px !important;
  }

  .card {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .why_astrology_hide_and_show {
    margin-top: 0;
  }

  .free_services_crous_next,
  .free_services_crous_prev {
    display: none !important;
  }

  .cdk-overlay-pane {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}

@media screen and (max-width: 360px) {
  .buttons .btnn {
    padding: 0 10px;
  }

  .buttons {
    right: 72px;
  }

  .buttons a {
    font-size: 12px;
  }

  .contact_btn .fa-user {
    margin-right: 0;
    margin-bottom: 0;
  }

  .buttons .btnn {
    justify-content: center;
    width: 30px;
  }
}

@media screen and (max-width: 350px) {
  .section_tabbaar_history .nav-tabs .nav-link {
    padding: 6px 8px;
    font-size: 10px;
  }
}
